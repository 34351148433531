@mixin flex-v-h-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin flex-v-center {
  display: flex;
  align-items: center;
}
// mobile Screen
@mixin xs {
  @media (max-width: 575.99px) {
    @content;
  }
}
