@import '../../../styles/variables.scss';
.breadContainer {
  margin-top: 64px;
  background: $white-color;
  border-bottom: 1px solid $light-grey-color;
  box-shadow: 0 1px 3px 0 rgba($color: $black-color, $alpha: 0.12),
    0 0 3px 0 rgba($color: $black-color, $alpha: 0.04);
  height: 36px;
  padding: 7px 15px;
  display: flex;
  align-items: center;
  gap: 0 10px;
  :global {
    .ant-breadcrumb {
      height: 100%;
      display: block;
    }
  }
}
