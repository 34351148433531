@import '../../../styles/variables.scss';
.content {
  :global {
    .ant-layout-content {
      padding: 20px;
    }
  }
}
.contentInner {
  & > div {
    border-radius: 15px;
    background-color: $white-color;
    padding: 15px 20px;
  }
}
