@import '../../../styles/variables.scss';
.loginWrap {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $light-grey-color-2;
  overflow: hidden;
  :global {
    .ant-card {
      background-color: transparent;
    }
    .ant-form-item {
      width: 100%;
    }
    .ant-card-body {
      padding: 0;
    }
  }

  input {
    height: 30px;
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px $white-color inset !important;
      &:hover,
      &:focus,
      &:active {
        -webkit-box-shadow: 0 0 0 30px $white-color inset !important;
      }
    }
  }
}
.loginCardWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 32px);
  .loginCardInner {
    min-width: 350px;
    max-width: 500px;
    margin: 0 auto;
  }
  :global {
    .ant-card {
      border: 0;
    }
    .ant-btn {
      width: 100%;
    }
  }
}

.logoWrap {
  text-align: center;
  height: 65px;
  overflow: hidden;
  margin-bottom: 20px;
  img {
    max-width: 100%;
    height: auto;
  }
}
