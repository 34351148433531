@import '../../../../styles/variables.scss';

.siderContainer {
  height: calc(100% - 64px);
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: $light-grey-color;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $light-grey-color;
    border-radius: 10px;
    height: 10px;
  }
  :global {
    .ant-menu-item-selected {
      font-weight: $bold;
      &:after {
        position: absolute;
        right: 0;
        top: 0;
        width: 10px;
        background-color: rgba($color: $suplift-color, $alpha: 0.8);
        height: 100%;
      }
    }
  }
}
