@import '../../../styles/main.scss';

.loadingContainer {
  height: calc(100vh - 100px);
  overflow: hidden;
  @include flex-v-h-center;
}
.loaderInnerWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loadingText {
  margin-top: 10px;
  font-weight: $bold;
  text-align: center;
}
.loader {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: $suplift-color $suplift-color transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent $yellow-color $yellow-color;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 52px;
  height: 52px;
  border-color: $suplift-color $suplift-color transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
