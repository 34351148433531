.langMenu {
  :global {
    .ant-dropdown-menu-item {
      svg {
        width: 20px;
        height: 20px;
        margin-inline-end: 6px;
      }
    }
  }
}
.langBtn.langBtn {
  border: 0;
  display: inline-flex;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    margin-inline-end: 6px;
  }
}
