@import '../../../../styles/variables.scss';
.sidebarLogoCont {
  position: relative;
  width: 100%;
  height: 64px;
  line-height: 64px;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $light-grey-color;
  .sidebarLogo {
    width: 110px;
    display: block;
  }
  .sidebarLogoColl {
    width: 42px;
  }
}
