@import './animation';
@import './variables';
@import './ltr.scss';
@import './rtl.scss';

body {
  font-family: $theme-font !important;
  margin: 0;
}
.search-btn {
  margin-inline-end: 10px;
}
.ant-table .ant-table-tbody > tr > td,
.ant-table .ant-table-thead > tr > th {
  text-align: center;
}
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  padding: 16px 10px;
}
.ant-form-item-label {
  font-weight: $medium;
}

.actions-wrap {
  svg {
    cursor: pointer;
    font-size: 16px;
    margin: 0 5px;
  }
}
.ScrollbarsCustom {
  min-height: 100vh !important;
}

.w-100 {
  width: 100%;
}
button {
  cursor: pointer;
}
.ant-upload-list-item-name {
  cursor: pointer;
}
.new-line {
  white-space: pre-line;
  text-align: left;
  margin-bottom: 0;
}
.ant-tooltip-inner.ant-tooltip-inner {
  text-align: center;
}

.mb-20 {
  margin-bottom: 20px;
}
.edit-link {
  color: $suplift-color;
  font-weight: $medium;
  text-decoration: underline;
  &:hover {
    color: $green-color;
    text-decoration: none;
  }
}
.capitalize {
  text-transform: capitalize;
}
.me-20 {
  margin-inline-end: 20px;
}
.red-color {
  color: $red-color;
}
.bg-green.bg-green {
  background-color: $green-color;
  &:hover {
    background-color: rgba($color: $green-color, $alpha: 0.8);
  }
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.mt-20 {
  margin-top: 20px;
}

.my-20 {
  margin: 20px 0;
}
.mb-0 {
  margin-bottom: 0;
}
:where(.css-dev-only-do-not-override-ph9edi).ant-btn-primary {
  background-color: $suplift-color;
  &:hover {
    background-color: $suplift-light-color !important;
  }
}
.my-10 {
  margin: 10px 0;
}
.m-0 {
  margin: 0;
}
