@import '../../../styles/variables.scss';
.cardWrap.cardWrap {
  background-color: rgba($color: $black-color, $alpha: 0.01);
  box-shadow: 0 5px 5px 0 rgba($color: $black-color, $alpha: 0.04),
    0 5px 5px 5px rgba($color: $black-color, $alpha: 0.04),
    0 5px 5px 0 rgba($color: $black-color, $alpha: 0.04);
  height: 100%;
  :global {
    .ant-card-head {
      //   min-height: 20px;
      border-bottom: none;
    }
    .ant-card-head-title {
      text-align: center;
    }
    .ant-card-body {
      text-align: center;
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }
}

.cardTitle {
  text-transform: uppercase;
  font-weight: $bold;
}
.icon {
  display: inline-block;
  margin-inline-end: 10px;
  font-weight: $bold;
  font-size: $font-size-20;
}

.contentWrap {
  @include flex-v-h-center;
  font-weight: $semi-bold;
  font-size: $font-size-15;
}
