@import '../../../styles/variables.scss';

.uploadBtn {
  all: unset;
  border: 1px solid $suplift-color;
  padding: 5px 10px;
  border-radius: 5px;
  color: $suplift-color;
  font-weight: $semi-bold;
  cursor: pointer;
  span {
    display: inline-block;
    margin-inline-start: 5px;
  }
}
