@import '../../../styles/variables.scss';

.avatarImageWrap {
  width: 100px;
  height: 100px;
  position: relative;

  :global {
    .ant-image {
      border-radius: 10px;
      overflow: hidden;
      border: 1px dashed $black-color;
    }
  }
  button {
    position: absolute;
    top: -10px;
    inset-inline-end: -10px;
    padding: 0;
    width: 22px;
    height: 22px;
    margin: 0;
    border-radius: 50%;
    outline: none;
    border: 0.2px solid $black-color;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    &:hover {
      background-color: $suplift-color;
    }
  }
}
