@import '../../../styles/variables.scss';
.datePicker.datePicker {
  position: relative;
  &.ant-form-item-has-error {
    display: none !important;
  }
  :global {
    .rmdp-container,
    .rmdp-input {
      display: block;
      width: 100%;
      height: 32px;
    }
  }
  .calendarIcon {
    display: inline-block;
    position: absolute;
    top: 50%;
    inset-inline-end: 10px;
    transform: translateY(-50%);
    opacity: 0.3;
  }
}
:global {
  .ant-form-item-has-error {
    .rmdp-input {
      border-color: $red-color;
    }
    svg {
      fill: $red-color;
    }
  }
}
