@import '../../../styles/variables.scss';
.mapWrap {
  width: 100%;
  height: 300px;
  & > div {
    width: 100%;
    height: 100%;
  }
}
.search {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
  input {
    padding: 10px;
    font-size: 14px;
    width: 100%;
  }
}

.searchList {
  background-color: $white-color;
  z-index: 1000;
}

.searchInput {
  width: 240px;
  box-shadow: 0 2px 6px rgba($color: $black-color, $alpha: 0.3);
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  border: 1px solid transparent;
  height: 32px;
  padding: 0 12px;
  border-radius: 3px;
  font-size: 14px;
  outline: none;
  text-overflow: ellipsis;
  position: absolute;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  top: 20px;
}
.skeletonWrap {
  :global {
    .ant-skeleton-block {
      height: 100%;
      .ant-skeleton-button {
        height: 100%;
      }
    }
  }
}
