@import '../../../styles/variables.scss';
.header.header {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  transition: width 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 4px $box-shadow-1;
  z-index: 99;
  margin-left: auto;
  padding: 0 0;
  padding-inline-start: 50px;
  @include xs {
    width: 100%;
  }
}
.headerLayoutColl {
  width: calc(100% - 80px);
}

.headerLayoutNotColl {
  width: calc(100% - 200px);
}
.userWrap {
  display: flex;
  align-items: center;
  gap: 0 10px;
  .userName {
    font-weight: $bold;
  }
  :global {
    .anticon-caret-down {
      cursor: pointer;
    }
    svg {
      fill: $grey-color;
    }
  }
}

.userMenuIcon {
  margin-inline-end: 6px;
}
.rightMenu {
  display: flex;
  align-items: center;
}
