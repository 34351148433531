@import '../../../styles/variables.scss';
.modalVideo {
  width: 100%;
  height: 100%;
}
.videoModal {
  :global {
    .ant-modal-content {
      border-radius: 10px;
      overflow: hidden;
      padding: 0;
      background-color: transparent;
    }
    .ant-modal-body {
      background-color: $black-color;
    }

    .ant-modal-close {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      color: $white-color;
      background-color: $red-color;
      &:hover {
        color: $white-color;
        background-color: $red-color;
        opacity: 0.3;
      }
    }
  }
}
