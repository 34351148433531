@import '../../../styles/variables.scss';

.languageTabs.languageTabs {
  &.ar {
    input,
    textarea {
      text-align: right;
    }
  }
  &.en {
    input,
    textarea {
      text-align: left;
    }
  }
  margin-bottom: 20px;
  :global {
    .ant-tabs-nav-wrap {
      justify-content: flex-start !important;
    }
    .ant-tabs-nav-list {
      flex: 0 0 100%;
    }
    .ant-tabs-tab {
      margin: 0 0;
      padding: 10px 40px;
      flex: 0 0 50%;
      justify-content: center;
    }
    .ant-tabs-tab-active {
      background-color: $light-grey-color-2;
    }
    .ant-form-item {
      padding-left: 10px;
    }
  }
}
.languageTab {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 25px;
    height: 25px;
    margin-inline-end: 10px;
  }
}

.translateBtn {
  margin: 0 10px 10px;
}
