.btnsWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
  button {
    &:first-child {
      margin-inline-end: 5px;
    }
  }
}
