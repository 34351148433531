.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: unset;
}

body[dir='rtl'] {
  .ant-modal-confirm .ant-modal-confirm-btns {
    float: left;
  }
  .ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
    margin-right: 8px;
    margin-left: 0;
  }
}
