@import './mixins.scss';
$theme-font: 'Cairo';
// colors
$grey-color: #0000004d;
$light-grey-color: #d3d3d3;
$light-grey-color-1: #f0f0f0;
$light-grey-color-2: #f0f2f5;
$grey-color: #495057;
$white-color: #fff;
$black-color: #000;
$border-color: #e5e5e5;
$green-color: #008000;
$yellow-color: #fce657;
$suplift-color: #f05e60;
$suplift-light-color: #ff8985;

$red-color: #ff0000;
$suplift-green-color: rgb(0, 179, 152);
$box-shadow-1: rgba(0, 21, 41, 0.08);

$font-size-25: 25px;
$font-size-20: 20px;
$font-size-15: 15px;

// font weights
$extra-bold: 900;
$bold: 700;
$semi-bold: 600;
$medium: 500;
$normal: 400;
