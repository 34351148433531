@import '../../../styles/variables.scss';

.sider.sider {
  position: relative;

  @include xs {
    inset-inline-start: -200px;
    position: fixed;
    top: 0;
    height: 100%;
    width: 200px;
    z-index: 999;
    .innerSideWrap {
      inset-inline-start: -200px;
      width: 200px;
    }
    &.siderOpen {
      inset-inline-start: 0;
      .innerSideWrap {
        inset-inline-start: 0;
      }
    }
  }
}
.innerSideWrap {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  width: 200px;
  height: 100%;
  background-color: $white-color;
}
.innerSiderWrapCollapsed {
  width: 80px;
}

.fade {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($color: $black-color, $alpha: 0.3);
  width: 100%;
  height: 100%;
}
