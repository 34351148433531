@import '../../../styles/variables.scss';
.filterDrop {
  border-radius: 6px;
  background: $white-color;
  box-shadow: 0 1px 6px rgba($color: $black-color, $alpha: 0.2) !important;
  padding: 10px;

  :global {
    input,
    .ant-select {
      width: 125px;
      margin-inline-end: 8px;
    }
    .rmdp-input {
      height: 30px;
    }
  }
}
