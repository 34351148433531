@import '../../../styles/variables.scss';

.copyIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px 5px;
  border-radius: 3px;
  svg {
    width: 15px;
    height: 15px;
  }
  outline: 0;
  border: 1px solid $grey-color;
  background: $white-color;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  @media only screen and (max-width: 767px) {
    opacity: 1;
  }
}
