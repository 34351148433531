@import '../../../styles/variables.scss';

.cropControls {
  display: flex;
  align-items: center;
  gap: 0 10px;
  position: relative;
}
.uploadInput {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  height: 35px;
  cursor: pointer;
  width: 85px;
}
.uploadBtn {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  z-index: 1;
  padding: 0.375rem 0.75rem;
  font-weight: $normal;
  line-height: 1.5;
  color: $suplift-color;
  background-color: $white-color;
  border: 1px solid $suplift-color;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 0 5px;
}

.aspectBtn {
  background-color: transparent;
  outline: 0;
  border: 1px solid $black-color;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  :global {
    svg {
      color: $black-color;
    }
  }
}
.aspectBtnToggled {
  background-color: $suplift-color;
  :global {
    svg {
      color: $white-color;
    }
  }
}
.imagesWrapper {
  margin-top: 20px;
  :global {
    .ant-image {
      border-radius: 5px;
      overflow: hidden;
    }
    .ant-space-item {
      label {
        & > span {
          &:last-child {
            flex: 1;
          }
        }
      }
    }
    .ant-space,
    .ant-radio-group {
      width: 100%;
    }
    .ant-radio-wrapper {
      padding: 5px;
      border: 1px solid rgba($color: $black-color, $alpha: 0.1);
      border-radius: 5px;
      width: 100%;
      &.ant-radio-wrapper-checked {
        border-color: $suplift-color;
      }
    }
  }
}
.titleWrap {
  @include flex-v-center;
  gap: 10px;
}
.cropperWrapper {
  @include flex-v-h-center;
}

.cropperWrapper {
  @include flex-v-center;
  background-color: rgba($color: $black-color, $alpha: 0.02);
}
.cropper {
  max-height: 200px;
  overflow: hidden;
  .cropperImage {
    object-fit: contain;
  }
}

.croppedImageWrap {
  .croppedImageDesc {
    font-weight: $bold;
    display: block;
    margin: 10px 0;
    small {
      font-weight: $normal;
    }
  }

  :global {
    .ant-image {
      border: 1px dashed rgba($color: $black-color, $alpha: 0.4);
      overflow: hidden;
      border-radius: 10px;
    }
  }
}

.radioImageItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .editAddBtnsWrap {
    button {
      border: 0;
      width: 30px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
    }
  }

  .editBtn {
    color: $green-color;
    &:hover {
      color: $white-color;
      background-color: rgba($color: $green-color, $alpha: 0.5);
    }
  }
}
.removeBtn {
  margin-inline-start: 5px;
  color: $red-color;
  background-color: $light-grey-color-1;

  &:hover {
    color: $white-color;
    background-color: rgba($color: $red-color, $alpha: 0.5);
  }
}
